let text = document.querySelector('#automatisation-text')
console.log({text})
let buttonShow = document.querySelector('#automatisation-button')
let smallTextBool = true
let originalText = 'За допомогою JediDesk ви зможете заощадити час та сили на підтримці клієнтів. Готова база знань дозволяє автоматизувати до 70% типових заявок, дозволяючи вам зосередитись на інших завданнях. Отримуйте та передавайте дані про клієнтів за допомогою Jedi та використовуйте їх у повній мірі для ще більшої автоматизації. Зберіть всю аналітику та дані в одному місці та переконайтеся, що ваші клієнти отримують найкраще обслуговування.'
let smallText = 'За допомогою JediDesk ви зможете заощадити час та сили на підтримці клієнтів. Готова база знань дозволяє автоматизувати до 70% типових заявок...'
let engText = document.querySelector('#automatisation-text-eng')
let butonEng = document.querySelector('#automatisation-button-eng')
let originalTextEng = 'With the help of JediDesk you can save a lot of time on customer support. The ready-made knowledge base can automate up to 70% of typical applications, allowing you to focus on other tasks. Receive and share customer data with Jedi and use them fully for even more automation. Collect all the analysis and data in one place and make sure your customers get the best service.'
let smallTextEng = 'With the help of JediDesk you can save a lot of time on customer support. The ready-made knowledge base can automate up to 70% of typical applications...'



if (buttonShow) {
buttonShow.addEventListener('click', () => {
    console.log(123123123)
    if (smallTextBool) {
        text.textContent = originalText
        smallTextBool = false
         buttonShow.textContent = 'Сховати'
       
    } else {
        text.textContent = smallText
        smallTextBool = true
         buttonShow.textContent = 'Дивтися більше'
    }
})
}

if (butonEng) {
       console.log(456734567457)
    butonEng.addEventListener('click', () => {
    if (smallTextBool) {
       engText.textContent = originalTextEng
       smallTextBool = false
       butonEng.textContent = 'Hide'
    } else {
       engText.textContent = smallTextEng
       smallTextBool = true
       butonEng.textContent = 'Show more'
    }
})
}
